import { render, staticRenderFns } from "./CreateClass.vue?vue&type=template&id=7a129105&"
import script from "./CreateClass.vue?vue&type=script&lang=js&"
export * from "./CreateClass.vue?vue&type=script&lang=js&"
import style0 from "./CreateClass.vue?vue&type=style&index=0&id=7a129105&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports